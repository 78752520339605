
























































































































































// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { LSS } from '@core/services';
import { decodeToken } from '@shared/utils/commom.util';
import { CB_Subs } from 'src/utils/apptweakqueries';
import Vue from 'vue';
import { LoginService } from '@services/login.service';
import { ChargebeeService } from '@services/chargebee.service';
export default Vue.extend({
  name: 'SubscriptionPlan',
  data: () => ({
    dialog: false,
    notifications: false,
    sound: true,
    widgets: false,
    isAdmin: false,
    sub_id: ""
  }),

  methods: {
    openPortal() {
      // const sub_id = this.sub_id
      // var chargebeeInstance = Chargebee.getInstance();
      // let cbPortal = chargebeeInstance.createChargebeePortal();
      // cbPortal.openSection({
      //   sectionType: Chargebee.getPortalSections().PAYMENT_SOURCES,
      // },
      // {
      //   paymentSourceAdd: async function() {
      //     await ChargebeeService.reActivateSubscription({subscription_id: sub_id})
      //     const UserID = decodeToken(LSS.token).UserId
      //     const SessID = LSS.sessId
      //     const token = await LoginService.getUserDetails({
      //       UserID: UserID,
      //       SessId: SessID
      //     })
      //     .catch(e => {
      //       return false
      //     });

      //     LSS.token = token
      //     window.location.reload();
      //   },
      // });
    },

    subscriptionDetailsFetch() {
      const custId = decodeToken(LSS.token).TeamID
      this.$apollo
        .query({
          query: CB_Subs,
          variables: {
            custId: custId,
          },
        })
        .then((data) => {
          this.subscriptionDataCal(data.data.getSubsForCust.list[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    subscriptionDataCal(subData) {
      this.sub_id = subData.TeamID;
    },
  },

  mounted() {
    if(decodeToken(LSS.token).Role == 'ADMIN') {
      this.isAdmin = true
    }

    this.subscriptionDetailsFetch()
  }
});
