var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { staticStyle: { padding: "82px 0 0 0" }, attrs: { fluid: "" } },
    [
      _c("v-row", { staticClass: "h-100 primary" }),
      _c(
        "v-row",
        { staticClass: " " },
        [
          [
            _c(
              "v-card",
              {
                staticClass:
                  "mx-auto px-9 py-12 d-flex justify-center flex-column align-center position-center",
                attrs: { "max-width": "600px" },
              },
              [
                _c("v-card-text", [
                  _c(
                    "p",
                    { staticClass: "text-h4 text--primary text-center" },
                    [_vm._v(" Your License has expired ")]
                  ),
                  _c("div", { staticClass: "text--primary text-center" }, [
                    _vm._v(" Kindly contact kochava sales team for renewal. "),
                  ]),
                  !_vm.isAdmin
                    ? _c(
                        "div",
                        { staticClass: "text-h6 text--primary text-center" },
                        [_vm._v(" Contact your Admin to upgrade you plan ")]
                      )
                    : _vm._e(),
                ]),
                _c("v-card-actions", [void 0], 2),
              ],
              1
            ),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }